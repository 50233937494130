<template>
  <div v-if="slack">
    <v-container fluid class="pt-0 px-0">
     <v-img v-if="slack.header_image" :src="'https://directus.offenestadt.info/assets/' + slack.header_image.id" style="margin:auto" max-height="400px">
        <v-container class="white--text fill-height">
          <v-row align="center" no-gutters>
            <v-col cols="12">
              <ColorCircle color="white" />
            </v-col>
            <v-col cols="12" md="6">
              <div v-html="slack.header_content" style="clear:both;" />
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-container>

    <v-container v-if="slack" class="pa-0 my-8">
      <v-row justify="space-around" class="ma-0">
        <v-col cols="12" sm="12" md="6">
          <ContentCard card-color="transparent" circle-color="yellow" font-color="white" :headline="slack.box_1_headline" :content="slack.box_1_content"/>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <ContentCard card-color="white" circle-color="red" font-color="black" :headline="slack.box_2_headline" :content="slack.box_2_content"/>
        </v-col>
        <v-col cols="12" justify="center">
          <a target="_blank" href="https://join.slack.com/t/openknowledgegermany/shared_invite/zt-ri8mqsir-gPcq_HzPd2W52sCTkyUujg">
          <img style="margin: auto; display:block" src="../assets/logos/Slack_RGB_White.svg" width="300" />
          </a>
          <p class="white--text text-sm-h5" style="text-align: center">
            Klicke <a target="_blank" href="https://join.slack.com/t/openknowledgegermany/shared_invite/zt-ri8mqsir-gPcq_HzPd2W52sCTkyUujg">hier</a> um dich anzumelden.
            <br /><br />
            oder füge den Channel der Open Knowledge Foundation deinem Slack hinzu: 
            <br />
            openknowledgegermany.slack.com
          </p>
        </v-col>
        <v-col cols="12" sm="4">
          <v-img class="ma-4" style="margin:auto;" src="../assets/logos/Logo_Code_for_Germany.svg" width="200px" contain />
        </v-col>
        <v-col cols="12" sm="4">
          <v-img class="ma-4" style="margin:auto;filter:invert()" src="../assets/logos/Open_Knowledge_Foundation_Deutschland_Logo.svg" width="200px" contain />
        </v-col>
        <v-col cols="12" sm="4">
          <v-img class="ma-4" style="margin:auto;" src="../assets/logos/CodeForHamburg.svg" max-height="90px" width="200px" contain />
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import ColorCircle from '@/components/ColorCircle.vue'
import ContentCard from '@/components/ContentCard.vue'

export default {
  name: 'AboutUs',
  components: {
    ColorCircle,
    ContentCard,
  },
  data: () => ({
    slack:null
  }),
  async created() {
    let temp = await this.$client.items("slack").readMany({ fields: ['*.*']})
    this.slack = temp.data
  }
}
</script>